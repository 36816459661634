<template>
  <v-app>
    <core-app-bar />

    <template>
      <v-main class="grey lighten-3">
        <div
          id="core-view"
          class="text-center"
        >
          <v-icon
            :size="200"
            class="my-16"
            style="--fa-primary-color: #882F89; --fa-secondary-opacity: 1; --fa-secondary-color: #151353;"
          >
            fad fa-telescope
          </v-icon>
          <div class="text-h3">
            The page you are looking for cannot be found
          </div>
        </div>
      </v-main>
    </template>

    <core-footer />
  </v-app>
</template>

<script>
  export default {
    components: {
      CoreFooter: () => import('@/components/core/Footer'),
      CoreAppBar: () => import('@/components/core/AppBar'),
    //   CoreView: () => import('@/components/core/View'),
    },
  }
</script>
